$(function() {
  window.rightDrawerOpen = false

  var openRightDrawer = function() {
    $('#rightDrawer').addClass('active')
    window.rightDrawerOpen = true
  }
  var closeRightDrawer = function() {
    $('#rightDrawer').removeClass('active')
    window.rightDrawerOpen = true
  }

  var loadDrawerContent = function(url) {
    var drawerContainer = $('#rightDrawer')
    $.ajax({
      url: url,
      type: 'GET',
      beforeSend: function(xhr) {
        drawerContainer.find('.draw-loading').removeClass('d-none')
      },
      success: function(data, xhr) {
        drawerContainer.find('.drawer-content').html(data)
      },
      complete: function(xhr) {
        drawerContainer.find('.draw-loading').addClass('d-none')
      }
    })
  }
    
  $(document).on('click', '[data-action="open-drawer"]', function(e) {
    e.preventDefault()
    var contentSource = $(this).data('src')
    openRightDrawer()
    loadDrawerContent(contentSource)
  })

  $(document).on('click', '[data-action="close-drawer"]', function(e) {
    e.preventDefault()
    closeRightDrawer()
  })


})